<template>
  <Head>
    <title>{{ this.campaign_name }} | M-Changa</title>
    <meta name="description" :content="this.fundData.custom_sentence" />
    <meta name="author" content="Mobi Changa" />
    <meta property="og:type" content="website" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <meta
      property="og:url"
      :content="this.baseURL + '/fundraiser/' + this.accountNumber"
    />
    <meta property="og:title" :content="this.campaign_name + ' | M-Changa'" />
    <meta property="og:description" :content="this.fundData.custom_sentence" />
    <meta property="fb:app_id" :content="this.facebookID" />
    <meta property="og:image" :content="this.featureImage" />
  </Head>
  <div>
    <section
      id="slider"
      class="slider-element inner-page-slider slider-share-fundraiser center"
      v-if="step != 8"
    >
      <div class="texture-overlay">
        <div class="texture-left">
          <img
            src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117724/web/zigzag-texture.svg"
          />
        </div>
        <div class="texture-right">
          <img
            src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117724/web/zigzag-texture.svg"
          />
        </div>
      </div>
      <div class="slider-inner vertical-middle md:pt-22 pt-12">
        <div class="container">
          <div class="center mb-2">
            <div style="display: inline-block; vertical-align: top">
              <li
                class="breadcrumb-item active cng_flag"
                aria-current="page"
                v-if="this.available_countries.includes(myCountryCode)"
              >
                &nbsp;
                <img
                  :src="this.country_flag[myCountryCode]"
                  :alt="myCountryCode"
                />
              </li>
              <li
                class="breadcrumb-item active cng_flag"
                aria-current="page"
                v-else
              >
                &nbsp;
                <img
                  src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,q_auto:low,w_50/v1664437758/web/kenya.png"
                  :alt="KE"
                />
              </li>
            </div>
          </div>
          <div class="banner-heading">
            <h1>
              <span v-if="champion_code"> {{ champion_name }}</span>
              <span v-else-if="fundData">{{ fundData.campaign_name }}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="content-wrap" v-if="step == 8">
        <div class="container" id="donatenow">
          <div class="donation-payment-method" id="activation-pane">
            <h3 class="block-title">Activate Account</h3>
            <p class="center" v-if="currencyConfig['currency_code'] == 'XAF'">
              Pour vérifier votre identité et commencer la collecte de fonds,
              M-Changa vous demande de faire le premier don d'au moins
              {{ currencyConfig['currency_code'] }}
              {{ currencyConfig['activation_amount'] }} au compte
              {{ accountNumber }} en utilisant les détails avec lesquels vous
              vous êtes inscrit:
            </p>
            <p class="center" v-else>
              To verify your identity and start fundraising, M-Changa requires
              you to make the first mobile money donation of at least
              {{ currencyConfig['currency_code'] }}
              {{ currencyConfig['activation_amount'] }} to Account
              {{ accountNumber }} using the phone number you registered with:
            </p>
            <p
              class="center"
              v-if="
                isFlutterwave == true &&
                myCurrency == 'XOF' &&
                myCountryCode != 'NE'
              "
            >
              <b>Cliquez</b> sur le bouton ci-dessous pour faire votre don,
              après quoi vous recevrez un SMS et une notification par e-mail sur
              la façon dont vous pouvez accéder à votre compte. <br />
              Merci d'avoir choisi M-Changa.
            </p>
            <p
              class="center"
              v-if="
                isFlutterwave == true &&
                myCurrency == 'XOFF' &&
                myCountryCode == 'NE'
              "
            >
              Envoyez votre don au numéro de téléphone +221785976920, après quoi
              vous recevrez un SMS et une notification par e-mail sur la façon
              pour accéder à votre compte.<br />
              Merci d'avoir choisi M-Changa.
            </p>
            <p
              class="center"
              v-if="isFlutterwave == true && myCurrency != 'XOF'"
            >
              <b>Click</b> on the button below to make your donation after which
              you will get an SMS and an Email notification on how you can
              access your account. <br />Thank you for choosing M-Changa.
            </p>
            <ActivateTab
              :paybill="paybill"
              :accountNumber="accountNumber"
              :businessName="businessName"
              :campaign_name="campaign_name"
              :campaign_id="campaign_id"
              :currencyConfig="currencyConfig"
              :isFlutterwave="isFlutterwave"
              :openTab="openTab"
              :myCountryCode="myCountryCode"
              :myCurrency="myCurrency"
            />
          </div>
        </div>
      </div>
      <div class="content-wrap" v-else>
        <div
          class="section nobg nomargin nopadding top-share-fundraiser-section"
        >
          <div class="container">
            <div class="row justify-content-center" v-if="fundData">
              <div class="col-md-6 col-sm-6 col-xs-12 ftimager">
                <div class="share-header-widget">
                  <carousel
                    :settings="settings"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                    :items-to-show="1"
                    :autoplay="autoplay"
                    class="carousel"
                    v-if="imagelist"
                  >
                    <slide v-for="image in imagelist" :key="image">
                      <img
                        :src="image.src"
                        class="featured-image"
                        v-if="featureImage != ''"
                      />
                    </slide>
                  </carousel>
                </div>
              </div>
              <div class="col-md-10" v-if="fundData">
                <div class="fundraiser-meta">
                  <div class="meta-status-wrap">
                    <span
                      class="status-item status-1"
                      :class="{ disabled: fundData_x.tr_flag == '' }"
                    >
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-share-feature-1.png"
                      />
                      <span>Treasurer Controlled</span>
                      <ToolTip
                        text="Funds raised are only paid out to the fundraiser after approval from treasurers verified by M-Changa. This additional oversight ensures proper management and use of your donations."
                      >
                      </ToolTip>
                    </span>
                    <span
                      class="status-item status-2"
                      :class="{ disabled: fundData.verification_status != 2 }"
                    >
                      <img
                        src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/ic-share-feature-2.png"
                      />
                      <span>Verified Fundraiser</span>
                      <ToolTip
                        text="A Verified Fundraiser has passed our thorough checks. We've confirmed the fundraiser's identity and validated their cause. This verification ensures your donation goes to the intended person and supports a genuine cause, giving you more confidence when donating."
                      >
                      </ToolTip>
                    </span>
                    <span class="status-item status-3">
                      <div
                        id="topscroller"
                        class="text-center"
                        v-if="leaderBoardShow"
                      >
                        <span class="leaderMedal" v-if="top_index == 0"
                          >🏆</span
                        >
                        <span class="leaderMedal" v-if="top_index == 1"
                          >⭐</span
                        >
                        <span class="leaderMedal" v-if="top_index == 2"
                          >🎈</span
                        >
                        {{ topDonors[top_index] }}
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12 share-top-action flex justify-center">
                <!-- <a href="#donatenow" class="button">dONATE</a>
                <a href="#share" class="button button-outline">sHARE</a> -->
                <SocialTab
                  :accountNumber="accountNumber"
                  :fbURL="fbURL"
                  :twURL="twURL"
                  :waURL="waURL"
                />
              </div>
              <div class="col-md-12">
                <div class="share-fundraiser-progress">
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="fundData.raised_perc"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="'width:' + fundData.raised_perc + '%'"
                    >
                      <span>{{ fundData.raised_perc }}%</span>
                    </div>
                  </div>
                  <div class="goal-statistic" v-if="alt_country_code == 'US'">
                    <strong>
                      {{ display_currency }}
                      <number
                        :format="theFormat"
                        :to="local_amount"
                        :duration="0"
                      ></number>
                    </strong>
                    funds raised of
                    <strong>
                      {{ display_currency }}
                      <number
                        :format="theFormat"
                        :to="local_target"
                        :duration="0"
                      ></number>
                    </strong>
                    goal
                  </div>
                  <div class="goal-statistic" v-else>
                    <strong>
                      {{ fundData.currency }}
                      <number
                        :format="theFormat"
                        :to="fundData.conv_amount"
                        :duration="0"
                      ></number>
                    </strong>
                    funds raised of
                    <strong>
                      {{ fundData.currency }}
                      <number
                        :format="theFormat"
                        :to="fundData.converted_target"
                        :duration="0"
                      ></number>
                    </strong>
                    goal
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div
                  class="statistic-item center"
                  v-if="alt_country_code == 'US'"
                >
                  <div class="counter">
                    <number
                      class="bold transition"
                      alt_country_code
                      :from="0"
                      :format="theFormat"
                      :to="local_amount"
                      :duration="duration / 2"
                      easing="Circ.easeOut"
                    />
                    <p
                      v-if="fundData.currency != currencyConfig.currency_code"
                      class="sm-text"
                    >
                      ( Current USD ExRates Apply )
                    </p>
                  </div>
                  <p>Funds Raised</p>
                </div>
                <div class="statistic-item center" v-else>
                  <div class="counter" v-if="champion_code">
                    <number
                      class="bold transition"
                      :from="0"
                      :format="theFormat"
                      :to="champion_amount"
                      :duration="duration / 2"
                      easing="Circ.easeOut"
                    />
                    <br />
                    <span
                      v-if="fundData.currency != currencyConfig.currency_code"
                      class="sm-text"
                    >
                      (
                      {{ display_currency }}
                      {{ local_champ_amount.toLocaleString('en') }}
                      )
                    </span>
                  </div>
                  <div class="counter" v-else>
                    <number
                      class="bold transition"
                      :from="0"
                      :format="theFormat"
                      :to="fundData.conv_amount"
                      :duration="duration / 2"
                      easing="Circ.easeOut"
                    />
                    <p
                      v-if="fundData.currency != currencyConfig.currency_code"
                      class="sm-text"
                    >
                      (
                      {{ display_currency }}
                      {{ local_amount }}
                      )
                    </p>
                  </div>
                  <p>Funds Raised</p>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="statistic-item center">
                  <div class="counter">
                    <number
                      class="bold transition"
                      :from="0"
                      :format="theFormat"
                      :to="fundData.cb_count"
                      :duration="duration / 2"
                      easing="Circ.easeOut"
                    />
                  </div>
                  <p>Supporters</p>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div
                  class="statistic-item center"
                  :class="{ disabled: daysleft < 1 }"
                >
                  <div class="counter">
                    <number
                      class="bold transition"
                      :from="0"
                      :format="theFormat"
                      :to="daysleft"
                      :duration="duration / 2"
                      easing="Circ.easeOut"
                    />
                  </div>
                  <p>Days Left</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center share-partners-logo">
              <div class="col-md-2 col-4" v-if="uk_aid">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/uk_logo.png"
                />
              </div>
              <div class="col-md-2 col-4" v-if="cdf_partner">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/cdf.png"
                />
              </div>
              <div class="col-md-2 col-4" v-if="cdf_partner">
                <img
                  src="https://res.cloudinary.com/changa/image/upload/f_auto,q_auto/v1643117694/web/uk_logo_copy.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="section nomargin share-description-section"
          v-if="fundData_x"
        >
          <ShareTab
            :story="story"
            :videoSource="videoSource"
            :imagelist="imagelist"
            :pageConfig="pageConfig"
            :imageCount="imageCount"
            :campaign_name="campaign_name"
          />
          <div id="donatenow">&nbsp;</div>
          <div class="container" id="donationContainer">
            <div
              class="row justify-content-center"
              v-if="partner_logo == 'senegal_1'"
            >
              <div class="col-12 justify-content-center">
                <p>
                  Cette campagne fait partie du programme Crowdfunding Academy.
                  Il a été développé par Tadamon, IsDB et IsFD, et le PNUD.
                </p>
              </div>
              <div class="row justify-content-center share-partners-logo">
                <div class="col-md-4 col-4">
                  <img
                    src="@/assets/images/isdb_logo_small.png"
                    class="mx-auto w-20"
                  />
                </div>
                <div class="col-md-4 col-4">
                  <img
                    src="@/assets/images/tadamongreensmall_small.png"
                    class="mx-auto w-56"
                  />
                </div>
                <div class="col-md-4 col-4">
                  <img
                    src="@/assets/images/isfd_Logo_small.png"
                    class="mx-auto w-16"
                  />
                </div>
              </div>
            </div>
            <div
              class="row justify-content-center"
              v-if="partner_logo == 'rotary_1'"
            >
              <div class="row justify-content-center share-partners-logo">
                <div class="col-md-3 col-3">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,g_face,h_180,w_280/v1/004/xlumqxq7bpvunk870cga"
                    class="mx-auto"
                  />
                </div>
                <div class="col-md-3 col-3">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,g_face,h_150,w_300/v1/004/uabj9tdkp8xn5xd1g7sk"
                    class="mx-auto"
                  />
                </div>
                <div class="col-md-3 col-3">
                  <img
                    src="https://res.cloudinary.com/changa/image/upload/c_thumb,f_auto,g_face,h_150,w_300/v1/004/eapv3iqohhgtsgx27b7f"
                    class="mx-auto"
                  />
                </div>
              </div>
            </div>
            <div class="donation-payment-method">
              <h3 class="block-title">Select donation method</h3>
              <PaymentTab
                :mpesaCheckoutAmout="mpesaCheckoutAmout"
                :primer="primer"
                :primer_b="primer_b"
                :primer_c="primer_c"
                :paybill="paybill"
                :accountNumber="accountNumber"
                :synonym="synonym"
                :businessName="businessName"
                :campaign_name="campaign_name"
                :campaign_id="campaign_id"
                :champion_code="champion_code"
                :currencyConfig="currencyConfig"
                :step="step"
                :activePayTab="activePayTab"
                :isFlutterwave="isFlutterwave"
                :myCurrency="myCurrency"
                :myCountryCode="myCountryCode"
                :myIntPrefix="myIntPrefix"
                :marketOption="marketOption"
                :alt_country_code="alt_country_code"
              />
            </div>
          </div>
        </div>
        <div
          class="section nomargin share-fund-champion"
          v-if="champion_fundraiser && !champion_code"
        >
          <div class="container">
            <div class="heading-block center">
              <h2>Fundraiser Champions</h2>
            </div>
            <div class="table-responsive fund-champion-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>Champions Name</th>
                    <th>Champion Page</th>
                    <th>Champion Collection</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="champ in champList" :key="champ">
                    <td>{{ champ.SubCauseName }}</td>
                    <td>
                      <a
                        :href="
                          '/fundraiser/' +
                          accountNumber +
                          '/?sc=' +
                          champ.ReferralCode
                        "
                        >{{ champ.ReferralCode }}</a
                      >
                    </td>
                    <td>
                      {{ display_currency }}
                      {{
                        (champ.amount / local_ex_rate)
                          .toFixed(2)
                          .toLocaleString('en')
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="text-center md:flex justify-center"
              v-if="champion_fundraiser == 5"
            >
              <a href="#" class="button">Register as Fundraising Champion</a>
              <a href="#" class="button button-outline">In Kind Donation</a>
            </div>
          </div>
        </div>
        <QuestionButtom />
        <FooterV />
      </div>
    </section>
  </div>
</template>
<script>
import { Head } from '@vueuse/head';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import { lazyLoadComponent } from '../utils/lazy-load-components';
import loader from '../components/loader.vue';

export default {
  beforeCreate() {},
  components: {
    Head,
    Carousel,
    Slide,
    Navigation,
    loader,
    PaymentTab: lazyLoadComponent({
      componentLoader: () => import('../components/PaymentTab.vue'),
      loadingComponent: loader,
    }),
    SocialTab: lazyLoadComponent({
      componentLoader: () => import('../components/SocialTab.vue'),
      loadingComponent: loader,
    }),
    ChampionTable: lazyLoadComponent({
      componentLoader: () => import('../components/championTable.vue'),
      loadingComponent: loader,
    }),
    ShareTab: lazyLoadComponent({
      componentLoader: () => import('../components/ShareTab.vue'),
      loadingComponent: loader,
    }),
    QuestionButtom: lazyLoadComponent({
      componentLoader: () => import('../components/QuestionBottom.vue'),
      loadingComponent: loader,
    }),
    ActivateTab: lazyLoadComponent({
      componentLoader: () => import('../components/ActivateTab.vue'),
      loadingComponent: loader,
    }),
    FooterV: lazyLoadComponent({
      componentLoader: () => import('../components/Footer/index.vue'),
      loadingComponent: loader,
    }),
    ToolTip: lazyLoadComponent({
      componentLoader: () => import('../components/ToolTip.vue'),
      loadingComponent: loader,
    }),
  },
  data() {
    return {
      fundData: {},
      duration: 2,
      mpesaCheckoutAmout: 500,
      primer: 500,
      primer_b: 2000,
      primer_c: 7500,
      campaign_id: 0,
      fundData_x: {},
      featureImage: '',
      story: '',
      videoSource: '',
      campaign_name: '',
      imagelist: [
        'https://res.cloudinary.com/changa/image/upload/f_auto,h_400,q_auto,w_auto/c_fill/v1492603822/commonshare/mchangawide.png',
      ],
      imageCount: 0,
      paybill: this.mchangaPAYBILL,
      businessName: this.airtelBusinessName,
      accountNumber: this.$route.params.id,
      synonym: '',
      daysleft: 0,
      champion_code: false,
      champion_fundraiser: false,
      champList: {},
      champion_name: '',
      champion_amount: 0,
      champion_donors: 0,
      currencyConfig: {},
      uk_aid: false,
      cdf_partner: false,
      local_amount: 0,
      local_ex_rate: 1,
      local_target: 1,
      display_currency: '',
      my_url: '',
      leaderBoard: 0,
      topDonors: [],
      top_index: 0,
      leaderBoardShow: false,
      pageConfig: {
        identifier: 0,
        url: window.location.href,
      },
      step: 5,
      activePayTab: false,
      isFlutterwave: 'no',
      marketOption: 'zero', // default ke market
      openTab: 1,
      facebookID: process.env.VUE_APP_FACEBOOK_ID,
      fbURL: 'https://www.facebook.com/sharer/sharer.php?u=',
      twURL: 'https://twitter.com/intent/tweet?text=',
      waURL: 'https://api.whatsapp.com/send?text=',
      alt_country_code: '',
      myCurrency: '',
      myCountryCode: '',
      myIntPrefix: '',
      partner_logo: '',
      country_logo: '',
      active_flag: 0,
      autoplay: 2000,
      settings: {
        itemsToShow: 1,
        snapAlign: 'center',
        autoplay: 2000,
      },
      breakpoints: {
        700: {
          itemsToShow: 1,
          snapAlign: 'center',
          autoplay: 3000,
        },
        1024: {
          itemsToShow: 1,
          snapAlign: 'start',
          autoplay: 3000,
        },
      },
    };
  },
  mounted() {
    this.topDonorUpdate();
  },
  methods: {
    topDonorUpdate() {
      setTimeout(() => {
        this.top_index += 1;
        if (this.top_index >= this.topDonors.length) {
          this.top_index = 0;
        }
        this.topDonorUpdate();
      }, 5000);
    },
  },

  async created() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ transactionID: this.$route.params.id }),
    };
    const response = await fetch(
      `${process.env.VUE_APP_ROOT_API}/service/fundraiser/share-page`,
      requestOptions,
    )
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        const primerAmount = Math.ceil((1.2 * data.primerAmount) / 100) * 100;
        this.fundData = data;
        let fund_country_code = data.country_code.toUpperCase();
        this.myIntPrefix = this.local_country_codes[fund_country_code];
        this.myCurrency = data.currency.toUpperCase();
        this.myCountryCode = data.country_code.toUpperCase();
        this.alt_country_code = data.alt_country_code;
        this.primer = primerAmount;
        this.campaign_id = data.campaign_id;
        this.pageConfig.identifier = data.campaign_id;
        this.campaign_name = data.campaign_name;
        this.mpesaCheckoutAmout = primerAmount;
        this.currencyConfig = data.currencyConfig;
        this.display_currency = this.fundData.currency;
        this.step = this.fundData.step;
        this.fbURL += encodeURIComponent(
          `${window.location.href}&t=${data.campaign_name} | M-Changa`,
        );
        this.twURL += encodeURIComponent(
          `${data.campaign_name} | M-Changa ${window.location.href}`,
        );
        this.waURL += encodeURIComponent(
          `${data.campaign_name} | M-Changa ${window.location.href}`,
        );
        if (
          this.available_countries.includes(data.country_code.toUpperCase())
        ) {
          this.active_flag = 1;
        }
        if (data.status == 1 && data.blocked == 0 && data.suspended == 0) {
          this.activePayTab = true;
          if (data.currency.toLowerCase() == 'kes') {
            this.isFlutterwave = false;
          } else if (this.flutter_currency_codes.includes(this.myCurrency)) {
            this.isFlutterwave = true;
            this.openTab = 5;
            this.marketOption = 'one'; // flutterwave - GH,UG
          } else {
            this.isFlutterwave = false;
          }
          // special MOMO only fundraisers
          if (data.currencyConfig.credit_card == 0) {
            this.marketOption = 'momo';
          }
          // special card only fundraisers
          if (data.currencyConfig.mobile_money == '0') {
            this.openTab = 6;
            this.marketOption = 'card';
          }
        }
        if (data.currencyConfig.currency_code != this.fundData.currency) {
          this.local_ex_rate = data.ex_rates.val;
          var my_local_amount = (data.conv_amount / this.local_ex_rate).toFixed(
            2,
          );
          var my_local_target = (data.target / this.local_ex_rate).toFixed(2);
          this.local_target = my_local_target
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.local_amount = my_local_amount
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.display_currency = data.ex_rates.display_currency;
          this.marketOption = 'zero'; // default to KE options
        }
        // senegal logo temp fix - wait for cloudinary access
        const sen_list = [
          58238, 58235, 58102, 58049, 57913, 57677, 57609, 57517, 57277, 58246,
          58433, 58488, 58633, 58756, 58749, 58798, 58825, 58928,
        ];
        if (sen_list.includes(data.transaction_id)) {
          this.partner_logo = 'senegal_1';
        }
        // Rotary logo temp fix - wait for cloudinary access
        const rot_list = [57216];
        if (rot_list.includes(data.transaction_id)) {
          this.partner_logo = 'rotary_1';
        }
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error('There was an error!', error);
      });

    //fetch other share page data - datax
    const requestOptions_x = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        transactionID: this.$route.params.id,
        campaign_id: this.campaign_id,
      }),
    };
    const response_x = await fetch(
      `${process.env.VUE_APP_ROOT_API}/service/fundraiser/share-page-details`,
      requestOptions_x,
    )
      .then(async (response_x) => {
        const data_x = await response_x.json();

        // check for error response
        if (!response_x.ok) {
          // get error message from body or default to response status
          const error = (data_x && data_x.message) || response_x.status;
          return Promise.reject(error);
        }
        this.fundData_x = data_x.shareData;
        this.story = this.fundData_x.message;
        if (this.story == '') {
          this.story = this.fundData_x.custom_sentence;
        }
        this.champion_fundraiser = this.fundData_x.champion_status;
        this.leaderBoard = this.fundData_x.auto_rank_prompts;
        this.videoSource = '';
        const video_str = this.fundData_x.video_frame;
        if (
          video_str != '' &&
          video_str.includes('iframe') &&
          video_str.includes('src')
        ) {
          var parser = new DOMParser();
          var parsedIframe = parser.parseFromString(
            video_str.replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
            'text/html',
          );
          let iFrame = parsedIframe.getElementsByTagName('iframe');
          this.videoSource = iFrame[0].src;
        }

        this.synonym = this.fundData_x.synonym;
        this.imagelist = data_x.images;
        const featImageID = Math.floor(Math.random() * this.imagelist.length);
        this.featureImage = this.imagelist[featImageID].src;
        this.imageCount = this.imagelist.length;
        this.daysleft = this.fundData_x.duration - this.fundData_x.fund_age;
        if (this.daysleft < 0) {
          this.daysleft = 0;
        }
        if (this.leaderBoard) {
          this.topDonors = data_x.topDonors;
        }
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error('There was an error!', error);
      });

    // top donors scroller
    if (this.leaderBoard) {
      //add leaderboard data
      var msgArr = this.topDonors;
      if (parseInt(msgArr.length) == 5) {
        this.leaderBoardShow = true;
        const leaders = [];
        for (let i = 0; i < msgArr.length; i++) {
          leaders[
            i
          ] = `${msgArr[i].donorName} - ${this.fundData.currency} ${msgArr[i].donorAmount}`;
        }
        this.topDonors = leaders;
      }
    }
    // champion details
    if (this.champion_fundraiser) {
      const champ_id = this.$route.query.sc;
      this.champion_fundraiser = true;

      this.champion_code = champ_id;
      //fetch champ details -
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          campaign_id: this.campaign_id,
          champ_id: champ_id,
        }),
      };
      const response = await fetch(
        `${process.env.VUE_APP_ROOT_API}/service/fundraiser/champion`,
        requestOptions,
      )
        .then(async (response) => {
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }
          this.champList = data;
          if (champ_id) {
            this.champion_name = data[0].SubCauseName;
            this.champion_amount = data[0].amount;
          }
          if (
            this.fundData.currencyConfig.currency_code != this.fundData.currency
          ) {
            this.local_champ_amount = (
              this.champion_amount / this.local_ex_rate
            ).toFixed(2);
          }
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error('There was an error!', error);
        });
    }
    // stripe session check
    const session_id = this.$route.query.session_id;
    if (session_id !== undefined) {
      const stripe_session = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ session_id: session_id }),
      };
      const stripe_resp = await fetch(
        `${process.env.VUE_APP_ROOT_API}/service/donation/stripe-session-status`,
        stripe_session,
      ).then(async (stripe_resp) => {
        const stripe_trans = await stripe_resp.json();
        if (stripe_trans.status == 'complete') {
          alert('M-CHANGA: Visa Donation Successful');
          window.location.replace(
            this.baseURL + '/fundraiser/' + this.accountNumber,
          );
        }
      });
    }
  },
};
</script>
<style>
.cng_flag {
  display: inline-flex;
}

#my_centered_buttons {
  display: flex;
  justify-content: center;
}

.sm-text {
  font-weight: lighter;
  font-size: x-small;
  color: #555;
}

.payment-text span {
  display: block;
  flex-direction: column;
  letter-spacing: 2px !important;
  line-height: 0.8em;
  font-weight: 400;
  margin: 0 0 5px 0;
  padding: 10px;
}

.share-header-widget .featured-image {
  max-height: 410px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 10px;
}

.share-header-widget .featured-image {
  object-fit: contain;
}

#topscroller {
  text-align: center;
  color: #475b06;
  font-size: 15px;
  font-weight: 400;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(170, 170, 170, 0.26);
}

.leaderMedal {
  font-size: 26px;
  padding: 0 5px;
  line-height: 20px;
  width: 40px;
  font-weight: 400;
  color: #e8952b;
}

.donation-payment-method {
  padding-bottom: 20px;
}

/*CSS for Pausing Scroller */

/*responsive */
@media (max-width: 675px) {
  .ftimager {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .share-header-widget {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .top-share-fundraiser-section .statistic-item {
    margin-bottom: 10px !important;
  }

  .section,
  .content-wrap {
    padding: 20px 0;
  }

  #topscroller {
    font-size: 12px;
    font-weight: 300;
  }

  .donation-payment-method {
    margin-top: 10px;
  }

  #donationContainer {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}
</style>
